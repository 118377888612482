<template>
  <div class="game-box-wrapper">
    <div class="image-wrapper">
      <img
        src="@/assets/img/img_joystock_lights.png"
        class="cover-image"
        loading="lazy"
        alt=""
      />
    </div>
    <div class="info-text-container">
      <h1 class="info-title">BONUS</h1>
      <p class="info-text">
        Bij het spelen van een variatie van zes verschillende spellen op MediaMasters Extra,
        verdienen de leerlingen dagelijks een bonus.
      </p>

      <div class="amount-played-ribbon">
        <img src="@/assets/icons/joystick.svg" />
        <div class="amount-played-text">
          {{ extraGamesPlayed }} / 6 gespeeld
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
export default {
  name: 'InfoGameBox',
  props: {
    extraGames: {
      type: Object,
      required: true,
    },
    currentDay: {
      type: Number,
      required: true,
    }
  },
  setup(props) {
    const extraGamesPlayed = computed(() => {
      try {
        const value = props.extraGames.reduce((acc, game) => {
          if (game.bitsPerDay[props.currentDay - 1] > 0) {
            acc++
          }
          return acc
        }, 0)
        return Math.min(value, 6)
      } catch (e) {
        print(e)
        return 0
      }
    })

    // Setup return
    return {
      extraGamesPlayed,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/global.scss";
@import "~@/assets/css/base.mixins";

$border-radius-game-box: rem(24);

.game-box-wrapper {
  position: relative;
  height: 240px;
  border-radius: $border-radius-game-box;
  background: #4d4c8a;
  box-shadow: inset 0 1px 2px 0 #341c69, 0 2px 30px 0 rgba(0, 0, 0, 0.2);
}

.image-wrapper {
  width: inherit;
  height: inherit;
  border-radius: $border-radius-game-box;
  width: 100%;
  position: relative;
  .cover-image {
    object-fit: cover;
    position: absolute;
    height: 100%;
    right: 0;
    border-radius: $border-radius-game-box;
  }
}

.info-text-container {
  position: absolute;
  top: rem(25);
  left: rem(30);
  width: 60%;
  color: #fff;
  font-size: rem(15);


  .info-title {
    font-size: rem(20);
    margin-bottom: rem(10);
  }

  .info-text {
    font-size: rem(15);
    color: var(--gray-light_2);
  }
}

.amount-played-ribbon {
  display: flex;
  align-items: center;
  margin-top: 25px;
  justify-content: center;
  width: 210px;
  height: 48px;
  background-color: #2D2E65;
  color: white;
  font-size: 18px;
  font-weight: 600;
  border-radius: $border-radius-game-box;

  img {
    margin-left: 2px;
    margin-right: 6px;
    width: 32px;
  }
}

@media screen and (max-width: 800px) {
  .game-box-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .image-wrapper {
    border-radius: $border-radius-game-box;
    .cover-image {
      height: 100%;
      width: 100%;
      border-radius: $border-radius-game-box;
    }
  }
}
</style>
