<template>
  <div>
    <RankBanner v-if="showRankingLink" :rank="rank" :bits-amount="bitsAmount" />
    <h2 class="page-section-title results-title"> {{ $t('OVERVIEW.RESULTS_FINISHED_TITLE') }} </h2>
    <p v-if="classId === -1" class="page-section-text results-text">{{ $t('OVERVIEW.PRE_RANKING_NO_SCHOOL') }}</p>
    <template v-else>
      <i18n-t v-if="showRankingLink" class="page-section-text results-text" keypath="OVERVIEW.RESULTS_FINISHED_TEXT" tag="p">
        <router-link :to="{name: ROUTE_NAMES_TEACHER_PORTAL.RANKING}">{{ $t('OVERVIEW.VIEW_FINAL_SCORE') }}</router-link>
      </i18n-t>
      <i18n-t v-else class="page-section-text results-text" keypath="OVERVIEW.POST_WEEK_PRE_RESULTS" tag="p">
        {{ rankingDate }}
      </i18n-t>
    </template>


    <div class="theme-mission-wrapper">
      <h2>{{ $t('OVERVIEW.PLAY_A_THEME_MISSION.TITLE') }}</h2>
      <p >{{ $t('OVERVIEW.PLAY_A_THEME_MISSION.DESCRIPTION') }}</p>
      <a :href="extraGamesRoute">
        <button class="pink-button">{{ $t('OVERVIEW.PLAY_A_THEME_MISSION.BUTTON') }}</button>
      </a>
    </div>
  </div>
</template>

<script>
import { ROUTE_NAMES_TEACHER_PORTAL } from '@/router/modules/teacherPortal'
import { computed } from '@vue/runtime-core'
// import { useStore } from 'vuex'
import RankBanner from './RankBanner'
import store from '@/store'

export default {
  name: 'PostWeekResults',
  components: { RankBanner },
  props: {
    bitsAmount: {
      type: Number,
      required: false,
      default: 0
    },
    rank: {
      type: Object,
      default: null
    },
    classId: {
      type: Number,
      required: true
    },
    showRankingLink: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const extraGamesRoute = `${process.env.VUE_APP_ROOT_GAME}/extra-games/${props.classId}`
    const rankingDate = computed(() => {
      const date = new Date(store.getters.getWeekData.rankingDate)
      return date.toLocaleDateString()
    })

    return {
      ROUTE_NAMES_TEACHER_PORTAL,
      extraGamesRoute,
      rankingDate
    }
  },
  template: 'PostWeekResults',
}
</script>

<style lang="scss" scoped>

@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.theme-mission-wrapper {
  background: #33234E url("../../../assets/img/img_pattern_day1.png") no-repeat right;
  background-size: contain;
  color: white;
  text-align: center;
  border-radius: rem(20);
  padding: rem(30) 10%;

  display: flex;
  flex-direction: column;
  gap: rem(20);
  line-height: rem(30);


  a {
    width: rem(300);
    margin: 0 auto;
  }
}

.results-title {
  text-align: center;
}

.results-text {
  margin-top: rem(20);
  margin-bottom: rem(40);
  text-align: center;
}

</style>
