<template>
  <div
    class="mission"
    :class="{ expanded: isExpanded }"
    :style="cssVars"
    @click="onArrowClick"
  >
    <!-- Top -->
    <div
      ref="topEl"
      class="top"
      :style="[isExpanded ? {} : { height: 'inherit' }]"
    >
      <div class="top-wrapper">
        <div v-if="icon" class="icon-image-wrapper">
          <img :src="icon" />
        </div>
        <div v-if="!icon" class="icon-wrapper">
          <InlineSvg
            :src="require('@/assets/icons/icn_puzzle.svg')"
            class="icon"
          />
        </div>
        <div class="text-wrapper">
          <p class="title">{{ title }}</p>
          <p class="description">{{ timesPlayed }} keer gespeeld</p>
        </div>
        <InlineSvg
          class="arrow-icon"
          :src="require('@/assets/icons/icn_arrow.svg')"
        />
      </div>

      <!-- Weekly bits overview -->
      <div v-if="!isExpanded" class="bits-overview-wrapper">
        <DailyBitsEarned
          :current-day="currentDay"
          :bits-per-day="bitsPerDay"
          :start-date="startDate"
          :end-date="endDate"
          :new-bits="0"
          :show-lightbulb="false"
          :show-progress-bar="false"
          :style-options="{
            wrap: 'wrap',
            borderRadius: '12px',
            maxHeight: '60px',
            maxWidth: '60px',
          }"
        />
      </div>

      <!-- Amount wrapper -->
      <div v-if="!isExpanded" class="reward-amount-wrapper">
        <img src="@/assets/icons/icn_bits.svg" alt="" />
        <p class="reward-amount">Totaal verdiend: {{ rewardAmount }}</p>
      </div>
    </div>

    <!-- Bottom -->
    <Transition name="fade">
      <div ref="bottomEl" class="bottom" v-if="isExpanded">
        <p class="description" v-html="description"></p>
        <a :href="linkUrl" target="_blank">
          <button class="pink-button">{{ linkText }}</button>
        </a>
      </div>
    </Transition>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import { ref } from "vue";
import { computed } from "@vue/reactivity";
import { useI18n } from "vue-i18n";
import DailyBitsEarned from "@/components/elements/missions/DailyBitsEarned.vue";

export default {
  name: "Mission",
  components: {
    InlineSvg,
    DailyBitsEarned,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    linkText: {
      type: String,
      default() {
        return useI18n().t("OVERVIEW.VIEW_IN_MM_EXTRA");
      },
    },
    linkUrl: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
    rewardAmount: {
      type: Number,
      required: true,
    },
    timesPlayed: {
      type: Number,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    currentDay: {
      type: Number,
      required: true,
    },
    bitsPerDay: {
      type: Array,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },

  },
  setup() {
    const isExpanded = ref(false);
    const topEl = ref(null);
    const bottomEl = ref(null);

    const cssVars = computed(() => {
      return {
        "--top_height": `${topEl.value ? topEl.value.clientHeight : 0}px`,
        "--bottom_height": `${bottomEl.value ? bottomEl.value.clientHeight : 0}px`,
      };
    });

    function onArrowClick() {
      isExpanded.value = !isExpanded.value;
    }

    return {
      isExpanded,
      onArrowClick,
      topEl,
      bottomEl,
      cssVars,
    };
  },
  template: "Mission",
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.fade-enter-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-from,
.fade-enter-to {
  opacity: 1;
}

.mission {
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.1);
  border-radius: rem(20);
  background-color: white;
  height: var(--top_height);
  height: auto;


  overflow: hidden;
  transition: height 0.5s;
  cursor: pointer;

  .top {
    background-color: white;
    border-radius: rem(20);
    position: relative;
    display: flex;
    gap: rem(20);
    padding: rem(20);
    transition: background-color 0.5s;
    flex-direction: column;

    .top-wrapper {
      display: flex;
      text-align: left;
      justify-content: flex-start;
    }

    .icon-image-wrapper {
      width: 45px;
      height: 45px;
      flex-shrink: 0;
      margin-right: rem(12);
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: rem(10);
      }
    }

    .icon-wrapper {
      background-color: #00b7ac;
      border-radius: rem(10);
      width: fit-content;
      padding: rem(10);
      .icon {
        display: block;
      }
    }
    .arrow-icon {
      cursor: pointer;
      position: absolute;
      top: rem(20);
      right: rem(20);
      width: rem(12);
      height: rem(12);
      transform: rotate(90deg);

      :deep path {
        fill: #373b52;
      }
    }
    .text-wrapper {
      .title {
        font-size: rem(18);
        font-weight: 600;
        text-transform: uppercase;
        line-height: rem(22);
        padding-right: rem(16);
      }
      .description {
        color: #373b52;
        opacity: 0.75;
        font-size: rem(14);
        line-height: rem(19);
      }
    }

    .bits-overview-wrapper {
      margin-top: rem(3);
      margin-bottom: rem(40);
     
    }

    .reward-amount-wrapper {
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: rem(5) 0 rem(20) 0;
      background-color: #dfe9fc;
      height: rem(50);
      width: fit-content;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: rem(9);
      padding: rem(7) rem(20);

      img {
        height: 60%;
        object-fit: contain;
        object-position: center;
      }

      .reward-amount {
        font-size: rem(15);
        font-weight: 500;
        line-height: rem(17);
      }
    }
  }

  .bottom {
    padding: rem(20);

    .description {
      margin-bottom: rem(24);
    }

    .pink-button {
      padding-right: 0;
      padding-left: 0;
      width: 100%;
    }
  }
}

.mission.expanded {
  height: calc(var(--top_height) + var(--bottom_height));
  .top {
    background-color: #413873;
    border-radius: rem(20) rem(20) 0 0;
    .text-wrapper {
      .title,
      .description {
        color: white;
      }
    }

    .reward-amount-wrapper {
      border-radius: rem(5) 0 0 0;
    }
    .arrow-icon {
      transform: rotate(-90deg);

      :deep path {
        fill: white;
      }
    }
  }
}
</style>
